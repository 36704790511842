import styles from './TechBanner.module.css';

export default function TechBanner({techUsed}){

    return(
        <ul className={styles.techlist}>
            {techUsed.map((tech, index) => (
                <li key={index} className={styles[tech.toLowerCase()]}>
                    <img src={`/media/icons/${tech}.svg`} alt="" />
                    <span>{tech}</span>
                </li>
            ))}
        </ul>
    );
}