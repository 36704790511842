import { useState } from "react";
import styles from "./Carousel.module.css";


export default function Carousel({children: slides}){

    const [currentSlide, setCurrentSlide] = useState(0);

    function previous(){
        setCurrentSlide((currentSlide) => (currentSlide === 0 ? slides.length - 1 : currentSlide - 1));
    }

    function next(){
        setCurrentSlide((currentSlide) => (currentSlide === slides.length - 1 ? 0 : currentSlide + 1));
    }

    const slidersPos = {
        translate: `-${currentSlide * 100}% 0px`
    }

    const paginationCurrent = {
        scale: "1.1",
        opacity: "1"
    }

    return(
        <div className={styles.carousel}>
            <div style={slidersPos}>
                {slides}
            </div>

            <div className={styles.control}>
                <span className="material-symbols-outlined" onClick={previous}>chevron_left</span>
                <span className="material-symbols-outlined" onClick={next}>chevron_right</span>
                <div className={styles.pagination}>
                    {slides.map((slide, index) => (
                        index === currentSlide ? <span style={paginationCurrent} key={index} /> : <span onClick={() => setCurrentSlide(index)} key={index}/>
                        
                    ))}
                </div>
            </div>
        </div>
    );
}