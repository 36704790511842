import styles from './StarsBackground.module.css';

export default function StarsBackground(){

    return(
        <div className={styles.container}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
        </div>
    )
}

