import styles from './Skills.module.css';
import { motion } from 'framer-motion';

export default function Skills(){

    const fadeInAnimationVariants = {
        initial: {
          opacity: 0,
          y: 105,
        },
        animate: (index) => ({
          opacity: 1,
          y: 0,
          transition: {
            delay: 0.05 * index,
            duration: 0.5,
            ease: "easeOut"
          },
        }),
    };


    const developpement = [
        {name: "HTML", img: "HTML.svg"},
        {name: "CSS", img: "CSS.svg"},
        {name: "JS", img: "JavaScript.svg"},
        {name: "Reactjs", img: "React.svg"},
        {name: "Sass", img: "Sass.svg"},
        {name: "PHP", img: "PHP.svg"},
        {name: "Wordpress", img: "Wordpress.svg"},
        {name: "SQL", img: "sql.svg"}
    ];

    const outils = [
        {name: "Figma", img: "Figma.svg"},
        {name: "Git", img: "Git_icon.svg"},
        {name: "NPM", img: "npm.svg"},
        {name: "Photoshop", img: "photoshop.svg"},
        {name: "Illustrator", img: "illustrator.svg"}
    ];

      
      
    return(
        <section className={styles.skills}>
            <h2>Compétences</h2>
            <div className={styles.content}>

                <article>
                    <h3>Développement</h3>
                    <ul>
                        {developpement.map((element, index) => (
                            <motion.li
                                key={index}
                                variants={fadeInAnimationVariants} 
                                initial="initial" 
                                whileInView="animate" 
                                viewport={{ once: true }} 
                                custom={index}
                            ><img src={`/media/icons/${element.img}`} alt={element.name}/>{element.name}
                            </motion.li>
                        ))}
                    </ul>
                </article>

                <article>
                    <h3>Outils</h3>
                    <ul>
                        {outils.map((element, index) => (
                            <motion.li
                                key={index}
                                variants={fadeInAnimationVariants} 
                                initial="initial" 
                                whileInView="animate" 
                                viewport={{ once: true }} 
                                custom={index}
                            ><img src={`/media/icons/${element.img}`} alt={element.name}/>{element.name}
                            </motion.li>
                        ))}
                    </ul>
                </article>

            </div>
        </section>
    )
}

