import ProfileImage from '../../atoms/ProfileImage/ProfileImage';
import styles from './AboutMe.module.css';
import WebBackground from '../../atoms/WebBackground/WebBackground';
import { motion } from 'framer-motion';

export default function AboutMe(){

    //? remove useless div
    return(
        <div className={styles.about}>

            <div className={styles.wrapper}>
                <motion.div 
                    className={styles.textcontent}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ 
                        duration: 1.3, 
                        ease: "easeOut" 
                    }}
                >
                    <h2>Qui suis-je ?</h2>
                    <p>
                        Bientôt titulaire d'un BAC+3 BUT MMI spécialité développement web (Développement Web et Dispositifs Interactifs), je suis actuellement à la recherche d'un stage pour finaliser ma formation.<br/>
                        <br />
                        Calme, déterminé et curieux, j'ai fait le choix du développement web qui me passionne, en raison de son changement permanent, où je peux découvrir et apprendre chaque jour. <br />
                        <br />
                        Je dispose aussi d'un niveau d'anglais C2 : ayant beaucoup d'amis à l'étranger, je m'exprime en anglais couramment et suis habitué à lire et écouter des contenus anglophones. <br />
                        <br />
                        J'ai acquis une première expérience professionnelle lors d'un stage au sein de l'entreprise <a href="https://studio-calico.fr/" target="_blank" rel="noreferrer">Calico</a>, où j'ai effectué la maintenance de certains de leurs sites, j'ai également participé à la création complète du site <a href="https://www.piquboeuf.com/" target="_blank" rel="noreferrer">Piqu'Boeuf</a> (voir la partie projet).
                    </p>
                    <a href="/CV.pdf" target="_blank" rel="noreferrer">Mon CV</a>
                </motion.div>
            </div>
            
            <ProfileImage />
            <WebBackground />
        </div>
    )
}

