import { Fragment } from 'react';
import Carousel from '../../atoms/Carousel/Carousel';
import TechBanner from '../../atoms/TechBanner/TechBanner';
import styles from './ProjectModal.module.css';

export default function ProjectModal({project, closeModal}){


    return(
        <div className={styles.container} >

            <div className={styles.modal}>
                <button className={styles.closebutton} onClick={closeModal}>
                    <div />
                    <div />
                </button>

                <div className={styles.title}><h3>{project.title}</h3> <a href={project.links.site} target="_blank" rel="noreferrer">Voir le site</a></div>
                <div className={styles.content}>
                    <Carousel >
                        {project.img.map((element, index) => (
                            <img key={index} src={`/media/${element}`} alt="" />
                        ))}
                    </Carousel>
                    <TechBanner techUsed={project.technologies} />

                    {project.description.map((paragraph, index) => (
                            <Fragment key={index}>
                                <p>{paragraph}</p>
                                <br />
                            </Fragment>
                        ))}
                </div>
            </div>

        </div>
    );
}