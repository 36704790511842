import styles from './ProjectsDisplay.module.css';
import ProjectItem from '../../atoms/ProjectItem/ProjectItem';

export default function ProjectsDisplay({projectList, handleProjectClick}){

    return(
        <div className={styles.display}>
            {
                projectList.map((element, index) => (
                    <ProjectItem item={element} handleProjectClick={handleProjectClick} key={index}/>
                ))
            }
        </div>
    );
}