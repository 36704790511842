import { useEffect, useRef, useState } from 'react';
import StarsBackground from '../../atoms/StarsBackground/StarsBackground';
import styles from './Introduction.module.css';

export default function Introduction(){

    const testRef = useRef();

    const refs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef()
    ]

    // const typerAnim = (element, speed, intervalStorage) => {
    //     let i = 0;
    //     element.textContent = "";
    //     let txt = element.getAttribute("animtext");

    //     let delay = Number(element.getAttribute("delay"));

    //     setTimeout(() => {
    //         intervalStorage = setInterval(() => {
    //             element.textContent += txt[i++];
    //             if (i === txt.length) {
    //                 clearInterval(intervalStorage);
    //             }
    //         }, speed);
    //     }, delay)
    // }

    function typerAnim(element, speed){
        let savedText = element.getAttribute("animtext");
        element.textContent = "";
        let caracterArray = savedText.split('');

        let delay = Number(element.getAttribute("delay"));
    
        setTimeout(() => {
            caracterArray.forEach((caracter, i) => {
                setTimeout(() => {
                    element.textContent += caracter; 
                }, i * speed)});
        }, delay)
    }

    useEffect(() => {
        refs.map((ref) => {
            typerAnim(ref.current, 7);
        })
    })
 
    return(  
        <div className={styles.intro}>
            <StarsBackground />
            <div className={styles.texts}>
                <p delay="0" ref={refs[0]} animtext="ls -l competences"></p>
                <p delay="385" ref={refs[1]} animtext="drwx------ 1 justin justin 4096 Mar 20 14:55 html"></p>
                <p delay="770" ref={refs[2]} animtext="dr-x------ 1 justin justin 4096 Mar 20 14:55 css"></p>
                <p delay="1155" ref={refs[3]} animtext="drwx------ 1 justin justin 4096 Mar 20 14:55 javascript"></p>
                <p delay="1540" ref={refs[4]} animtext="drw------- 1 justin justin 4096 Mar 20 14:55 php"></p>
                <p delay="1925" ref={refs[5]} animtext="dr-------- 1 justin justin 4096 Mar 20 14:55 sql"></p>
                <p delay="2310" ref={refs[6]} animtext="cat message"></p>
            </div>

            <h1>CARD Justin</h1>
            <div className={styles.gradient}></div>

        </div>
    )
}

