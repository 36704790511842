import styles from "./Filter.module.css";

export default function Filter({name, placeholder, values, setValue}){
    return(
        <select className={styles.filter} name={name} onChange={(event) => setValue(event.target.value)}>
            <option value="">{placeholder}</option>
            {values.map((value, index) => (
                <option key={index} value={value}>{value}</option>
            ))}
        </select>
    );
}