import styles from '../styles/modules/Mainpage.module.css';

import ContactForm from '../components/templates/ContactForm/ContactForm';
import AboutMe from '../components/templates/AboutMe/AboutMe';
import Introduction from '../components/templates/Introduction/Introduction';
import Projects from '../components/templates/Projects/Projects';
import Skills from '../components/templates/Skills/Skills';

export default function Mainpage(){

    return(
        <div className={styles.main}>
            <Introduction />
            <AboutMe />
            <div className={styles.gradient}></div>
            <Projects />
            <Skills />

            <ContactForm />
        </div>
    )
}

