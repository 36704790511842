import { Graphics } from "pixi.js";

class Particule extends Graphics {
    constructor(radius, color, x, y, speed, type){
        super(); // invoque des méthodes de la super class


        this.bbox = {x: 0, y: 0, w:800, h:600};

        this.color = color;
        this.radius = radius;
        this.speed = speed;

        this.x = x;
        this.y = y;

        // couleur de remplissage
        this.beginFill(color);
        // cercle
        this.drawCircle(0,0, radius);


        // définition du vecteur 
        const angle = Math.random() * Math.PI * 2; // crée un angle aléatoire
        this.vec = {x: Math.cos(angle), y: Math.sin(angle)};
    }


    move(){

        if (this.x + this.radius > this.bbox.w || this.x - this.radius < this.bbox.x) {
            this.vec.x *= -1;
        }
        if(this.y + this.radius > this.bbox.h || this.y - this.radius < this.bbox.y){
            this.vec.y *= -1;
        }

        this.x += this.vec.x * this.speed;
        this.y += this.vec.y * this.speed;
    }

    distParticules(particules, idx){

        this.clear();

        // redraw le cercle 
        this.beginFill(this.color);
        this.drawCircle(0, 0, this.radius);

        const distanceMin = 200; // * customisable distance minimale entre les points pour avoir un trait

        // calcul de la distance avec voisin
        for(let j = idx; j < particules.length ; j++){
            const voisin = particules[j];
            const a = this.x - voisin.x;
            const b = this.y - voisin.y;
            const d = Math.sqrt(a * a + b * b); 

            // si distance inférieure
            if(d < distanceMin){
                const v = 1 - (d / distanceMin);
                this.lineStyle(1, this.color, v); // tester avec la première valeur 0xFF0000 et deuxième a 0 particule couleur rouges (étrange bug visuel)
                this.moveTo(0, 0);
                this.lineTo(voisin.x - this.x, voisin.y - this.y);
            }
        }
    }
}


export {
    Particule
}
