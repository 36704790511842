import { useState } from 'react';
import styles from './ContactForm.module.css';
import { motion } from 'framer-motion';

export default function ContactForm(){

    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    
    const handleSend = async () => {
        setMailStatus(1);
        // data
        const data = {
            email: email,
            description: description,
        };
      
        fetch('./sendMail.php', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.text())
        .then(data => {
            console.log(Boolean(data));
            if(Boolean(data)){
                setMailStatus(2);
            }else{
                setMailStatus(3)
            }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    /**
     * 0 : not sent
     * 1 : loading
     * 2 : success
     * 3 : failed
     */
    const [mailStatus, setMailStatus] = useState(0);

    let buttonElement = null;

    if(mailStatus === 0) {
        buttonElement = <motion.button onClick={handleSend} initial={{ translateY: 35 }} whileInView={{ translateY: 0 }} viewport={{ once: true }} transition={{ duration: 0.3, ease: "easeOut" }}>Envoyer</motion.button>;
    }else if(mailStatus === 1){
        buttonElement = <motion.div className={styles.loading} >En cours d'envoi...</motion.div>;
    }else if(mailStatus === 2){
        buttonElement = <motion.div className={styles.success}>Message envoyé</motion.div>;
    }else if(mailStatus === 3){
        buttonElement = <motion.div className={styles.failed}>Échec de l'envoi</motion.div>;
    }
    

    // <motion.div
    //             initial={{ scale: 0.5, translateY: 100 }} whileInView={{ scale: 1, translateY: 0 }} viewport={{ once: true }} transition={{ duration: 0.3, ease: "easeOut" }}
    //             whileInView={{ scale: 1, translateY: 0 }}
    //             viewport={{ once: true }}
    //             transition={{ duration: 0.3, ease: "easeOut" }}
    // >

    return(
        <div className={styles.contact}>
            <div>
                <h2>Me Contacter</h2>
                <div className={styles.form}>
                    <motion.input 
                        initial={{ opacity: 0, translateX: "-95%" }}
                        whileInView={{ opacity: 1, translateX: 0 }}
                        viewport={{ once: true }}
                        transition={{ 
                            duration: 0.5, 
                            ease: "easeOut" 
                        }}

                        type="text"
                        onChange={(evt) => setEmail(evt.target.value)}
                        value={email} placeholder='Votre adresse mail'
                    />
                    <motion.textarea
                        initial={{ opacity: 0, translateX: "95%" }}
                        whileInView={{ opacity: 1, translateX: 0 }}
                        viewport={{ once: true }}
                        transition={{ 
                            duration: 0.5, 
                            ease: "easeOut" 
                        }}

                        onChange={(evt) => setDescription(evt.target.value)}
                        value={description} placeholder='Votre message...' 
                    />
                    {buttonElement}
                </div>
            </div>
        </div>
    )
}