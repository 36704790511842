import styles from './ProjectItem.module.css';
import { motion } from 'framer-motion';

export default function ProjectItem({item, handleProjectClick}){

    return(
        <motion.div 
            className={styles.item} 
            onClick={() => handleProjectClick(item)}

            initial={{ translateY: 180, opacity: 0 }}
            whileInView={{ translateY: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ 
                duration: 0.4, 
                ease: "easeOut" 
            }}
        >
            <div className={styles.preview}>
                <img src={`/media/${item.thumbnail}`} alt={item.title} />
                <p>{item.title}</p>
            </div>

            <div className={styles.technologies}>
                {item.technologies.map((name, index) => (
                    <div className={styles.tech} key={index}><img src={`/media/icons/${name}.svg`} alt="a" /></div>
                ))}
            </div>
        </motion.div>
    )
}