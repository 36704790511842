import { useState, useEffect, useContext } from 'react';
import styles from './Projects.module.css';
import { ProjectsContext } from '../../../context/ProjectsContext';
import ProjectsDisplay from '../../molecules/ProjectsDisplay/ProjectsDisplay';
import ProjectModal from '../../molecules/ProjectModal/ProjectModal';
import Filter from '../../atoms/Filter/Filter';

export default function Projects(){


    //*------Modal------
    // constext
    const {projectItems} = useContext(ProjectsContext);

    // modal option
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    // open modal
    function handleProjectClick(project){
        setSelectedProject(project);
        setModalVisible(true);
    }

    // close modal
    function closeModal(){
        setModalVisible(false);
    }

    
    //*------Options------
    const [search, setSearch] = useState("");

    // tech
    const [technologies, setTechnologies] = useState([]);
    const [selectedTech, setSelectedTech] = useState('');

    // year
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');

    // order
    const [recentToOld, setRecentToOld] = useState(true);

    useEffect(() => {
        const techSet = new Set();
        const yearSet = new Set();
    
        projectItems.forEach(project => {
            const projectYear = new Date(project.date).getFullYear();
            yearSet.add(projectYear);

            project.technologies.forEach(tech => {
                techSet.add(tech);
            });
        });
        
        setTechnologies(Array.from(techSet));
        setDates(Array.from(yearSet));
    }, [projectItems]);


    const filteredProjects = projectItems.filter(project => {
        // check if include string
        const titleMatch = project.title.toLowerCase().includes(search.toLowerCase());
        // check if include tech
        const techMatch = selectedTech === '' || project.technologies.includes(selectedTech);
        // check if include date
        const year = new Date(project.date).getFullYear().toString();
        const dateMatch = selectedDate === '' || year === selectedDate;

        // check if both conditions are true
        return (titleMatch && techMatch && dateMatch);
    });



    const sortedProject = filteredProjects.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        if(recentToOld){
            return dateB - dateA;
        }else{
            return dateA - dateB;
        }
    })


    return(
        <div className={styles.projects}>
            <h2>Projets</h2>
 
            {modalVisible && <ProjectModal project={selectedProject} closeModal={closeModal} />}
            <div className={styles.options}>
                <input type="text" onChange={(evt) => setSearch(evt.target.value)} value={search} placeholder='Rechercher' />

                <Filter name="tech" placeholder="Tous types" values={technologies} setValue={setSelectedTech} />
                <Filter name="date" placeholder="Toutes dates" values={dates} setValue={setSelectedDate} />

                <button onClick={() => setRecentToOld(prev => !prev)}>
                    <span className="material-symbols-outlined">low_priority</span>
                    Les plus {recentToOld ? "récents" : "vieux"}
                </button>

            </div>
            <ProjectsDisplay projectList={sortedProject} handleProjectClick={handleProjectClick} />
        </div>
    )
}