import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './styles/global.css';
import Mainpage from './routes/Mainpage';
import { ProjectsContextProvider } from './context/ProjectsContext';

const router = createBrowserRouter([
  {
    path: '/', // chemin vers la page par rapport a la root
    element: <Mainpage />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ProjectsContextProvider>
      <RouterProvider router={router}/>
    </ProjectsContextProvider>
  </React.StrictMode>
);