import styles from './ProfileImage.module.css';
import { motion } from 'framer-motion';

export default function ProfileImage(){
    

    return(
        <motion.div
            className={styles.profile}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ 
                duration: 1.3, 
                ease: "easeOut" 
            }}
        >
            <div className={styles.image}>
                <img src="/media/profile.jpg" alt="Justin CARD" />
            </div>
        </motion.div>
    )
}