import { createContext, useState, useEffect } from "react";

//* Définition du contexte
export const ProjectsContext = createContext([]);

//* Fonctionnalités du contexte
export function ProjectsContextProvider(props){

    const [projectItems, setProjectItems] = useState([]);


    //* fetch des informations de produits
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/projects.json');
                const jsonData = await response.json();
                setProjectItems(jsonData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    //* contenu du provider (data)
    const contextValue = {projectItems};

    return (
        <ProjectsContext.Provider value={contextValue}>
                {props.children}
        </ProjectsContext.Provider>
    )
}
