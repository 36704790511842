import { useEffect, useRef, useState } from 'react';
import styles from './WebBackground.module.css';
import { Application } from 'pixi.js';
import { Particule } from '../../../pixi/Particle';


export default function WebBackground(){

    const containerRef = useRef();
    let pixiApp = null;


    function initializeParticles(app, thewidth, theheight, particuleCanvaColor) {
        const particles = [];

        let mediaQuery = window.matchMedia("(max-width: 600px)");
        let particleQuantity;

        if(mediaQuery.matches){
            particleQuantity = 80;
        }else{
            particleQuantity = 120;
        }

        for (let i = 0; i < particleQuantity; i++) {
            const p = new Particule(Math.random() * 5, particuleCanvaColor, Math.random() * thewidth, Math.random() * theheight, Math.random() * 0.5);
            p.bbox.w = thewidth;
            p.bbox.h = theheight;
            app.stage.addChild(p);
            particles.push(p);
        }
        return particles;
      }
    
    
    function buildCanva(){
        // définition de la taille du canva
        //TODO fix height and widht (must use parent ref since height will prob be higher in the phone version)
        let thewidth = containerRef.current.offsetWidth;
        let theheight = containerRef.current.offsetHeight;
        let backgroundcolorCanva = '0x000000';
        let particuleCanvaColor = '0xFFFFFF';

        // définition de l'objet
        pixiApp = new Application({
            width: thewidth,
            height: theheight,
            backgroundColor: backgroundcolorCanva,
            antialias:true,
        });

        // définition de l'enplacement du canva
        containerRef.current.appendChild(pixiApp.view);

        let particles = initializeParticles(pixiApp, thewidth, theheight, particuleCanvaColor);

        // gestion de l'animation
        pixiApp.ticker.add(() => {
            for(let i = 0, p ; i < particles.length ; i++){
                p = particles[i];
                p.move();
                p.distParticules(particles, i + 1);
            }
        })
    }

    useEffect(() => {
        if(pixiApp == null){
            buildCanva();
        }
    }, []);


    return(
        <div ref={containerRef} className={styles.container}>
                      
        </div>
    )
}

